.print_preview 
{
    max-width:1000px;
    margin:50px auto;
    background:white;
    padding:20px;
}

.stats_headers 
{
    display:none;
    margin-bottom:20px;
    border-left:10px solid var(--primary-color);
    padding-left:12px;
    gap: 50px;
    margin-bottom:50px;
}
.stats_header_title 
{
    font-weight:800;
    width:fit-content;
    padding-right:20px;
    font-size:1.5rem;
    line-height:1.2;
    text-transform: none;
}
.stats_heeader_dates
{
    font-size:14px;
    text-transform: uppercase;
    amargin-bottom:10px;
}
.print_preview .stats_headers
{
    display:flex;
}
.print_preview canvas 
{
    width:100% !important;
}
.stats_logo 
{
    width:150px;
}


.stats_section
{
    min-height:100px;
    margin-bottom:50px;
    padding-top:40px;
}

.stats_break
{
    page-break-after: always;
}

.stats_title
{
    font-size:1rem;
    text-transform: uppercase;
    font-weight:400;
    line-height:1;
    margin:5px 0 50px 0;
    width:100%;
    background:var(--primary-color);
    color:white;
    padding:5px 10px;
}

thead .stats_table_title
{
    awidth:20% !important;

    background:white;
    color:var(--dark-color);
    afont-weight:bold;
}

.report_stats table
{
    font-size:0.9em;
    padding-bottom:40px;
    table-layout:fixed; 
    break-inside: avoid;
    width:100%;
}
.report_stats table.variable
{
    table-layout:auto;
}
.report_stats td, 
.report_stats th, 
.report_stats .td  
{
    text-align:right;
    padding:2px 8px;
}
.report_stats td:first-child,
.report_stats th:first-child, 
.report_stats .td:first-child
{
    text-align:left;
}

.report_stats th:first-child
{
    background:var(--primarylight-color);
}

.report_stats .financial_table tr:last-child td 
{
    font-weight:800;
    border-bottom:none;
}

.financial_charts 
{
    padding:0 10px;
    margin-bottom:50px;
}
.report_stats .service_table th:not(:first-child)
{
    width:15%;
}


#report_stats_iretia .stats_section 
{
    padding:0;
    margin:0 0 0 0;
}
#report_stats_iretia .stats_section.first 
{
    margin-top:40px;
}
#report_stats_iretia .stats_table_title
{
    font-weight:bold;
    background: var(--primarylight-color);
    border-bottom:none;
}
#report_stats_iretia th, #report_stats_iretia td {
    text-align: center; 
}

#report_stats_iretia th
{
    padding: 5px 8px;
}

#report_stats_iretia th:first-child , #report_stats_iretia td:first-child {
    text-align: left; 
}
.stats_farmaval_title 
{
    background:var(--primary-color);
    color:white;
}

td.bold 
{
    font-weight:800 !important;
    padding-top:30px !important;
}
td.tab 
{
    padding-left:40px !important;
}
@media print {

    .print_preview{
        margin:0;
        width:100%;
        padding:0;
    }
    body 
    {
        background:white;
        font-size:15px;
    }
}