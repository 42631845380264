.print_preview {
  background: #fff;
  max-width: 1000px;
  margin: 50px auto;
  padding: 20px;
}

.stats_headers {
  border-left: 10px solid var(--primary-color);
  gap: 50px;
  margin-bottom: 50px;
  padding-left: 12px;
  display: none;
}

.stats_header_title {
  text-transform: none;
  width: fit-content;
  padding-right: 20px;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.2;
}

.stats_heeader_dates {
  text-transform: uppercase;
  amargin-bottom: 10px;
  font-size: 14px;
}

.print_preview .stats_headers {
  display: flex;
}

.print_preview canvas {
  width: 100% !important;
}

.stats_logo {
  width: 150px;
}

.stats_section {
  min-height: 100px;
  margin-bottom: 50px;
  padding-top: 40px;
}

.stats_break {
  page-break-after: always;
}

.stats_title {
  text-transform: uppercase;
  background: var(--primary-color);
  color: #fff;
  width: 100%;
  margin: 5px 0 50px;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}

thead .stats_table_title {
  color: var(--dark-color);
  afont-weight: bold;
  background: #fff;
  awidth: 20% !important;
}

.report_stats table {
  table-layout: fixed;
  break-inside: avoid;
  width: 100%;
  padding-bottom: 40px;
  font-size: .9em;
}

.report_stats table.variable {
  table-layout: auto;
}

.report_stats td, .report_stats th, .report_stats .td {
  text-align: right;
  padding: 2px 8px;
}

.report_stats td:first-child, .report_stats th:first-child, .report_stats .td:first-child {
  text-align: left;
}

.report_stats th:first-child {
  background: var(--primarylight-color);
}

.report_stats .financial_table tr:last-child td {
  border-bottom: none;
  font-weight: 800;
}

.financial_charts {
  margin-bottom: 50px;
  padding: 0 10px;
}

.report_stats .service_table th:not(:first-child) {
  width: 15%;
}

#report_stats_iretia .stats_section {
  margin: 0;
  padding: 0;
}

#report_stats_iretia .stats_section.first {
  margin-top: 40px;
}

#report_stats_iretia .stats_table_title {
  background: var(--primarylight-color);
  border-bottom: none;
  font-weight: bold;
}

#report_stats_iretia th, #report_stats_iretia td {
  text-align: center;
}

#report_stats_iretia th {
  padding: 5px 8px;
}

#report_stats_iretia th:first-child, #report_stats_iretia td:first-child {
  text-align: left;
}

.stats_farmaval_title {
  background: var(--primary-color);
  color: #fff;
}

td.bold {
  padding-top: 30px !important;
  font-weight: 800 !important;
}

td.tab {
  padding-left: 40px !important;
}

@media print {
  .print_preview {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    background: #fff;
    font-size: 15px;
  }
}
/*# sourceMappingURL=path0.ab34ca25.css.map */
